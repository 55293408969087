/// <reference path="../../typings/index.d.ts" />

function createSliders() {

	if($('#related-slider').length) {
		$('#related-slider').carouFredSel({
			circular: true,
			infinite: false,
			responsive: false,
			scroll: { items: 1,
			},
			auto: false,
			items: {
				visible: 4
			},
			swipe: {
				onTouch: true
			},
			next: '#related-next',
			prev: '#related-prev'
		});
	}

	if($('#related-slider_main').length) {
		$('#related-slider_main').carouFredSel({
			circular: true,
			infinite: false,
			responsive: false,
			scroll: { items: 1,
			},
			auto: false,
			items: {
				visible: 4
			},
			swipe: {
				onTouch: true
			},
			next: '#related_main-next',
			prev: '#related_main-prev'
		});
	}
	if($('.brands-slider').length) {
		$('.brands-slider').carouFredSel({
			circular: true,
			infinite: false,
			responsive: false,
			scroll: { items: 1,
			},
			auto: false,
			items: {
				visible: 6
			},
			swipe: {
				onTouch: true
			},
			next: '#brands-next',
			prev: '#brands-prev'
		});
	}

	if($('#item_gallery-thumbs').length) {
		$('#item_gallery-thumbs').carouFredSel({
			synchronise: ['#item_gallery', false, true],
			infinite: false,
			auto: false,
			items: {
				visible: 5,
			},
			scroll: {
				items: 1,
				onBefore: function( data ) {
					data.items.old.eq(0).removeClass('selected');
					data.items.visible.eq(0).addClass('selected');
				}
			},
			next: '#item_gallery-thumbs-next',
			onCreate: function (data) {
				if(data.items.length < $('#item_gallery-thumbs').children().length) {
					$( "#item_gallery-thumbs li" ).unbind();
					$('#item_gallery-thumbs li').click(function() {
						$('#item_gallery-thumbs').trigger( 'slideTo', this );
					});
				} else {
					$("#item_gallery-thumbs").trigger("destroy");
					
					$( "#item_gallery-thumbs li" ).unbind();		
					$('#item_gallery-thumbs li').click(function() {
						$('#item_gallery').trigger( 'slideTo', $(this).index() );
						$(this).siblings('.selected').removeClass('selected');
						$(this).addClass('selected');
					});
				}
			},
			swipe: {
				onTouch: true
			}
		});
	}

	if($('#item_gallery').length) {
		$('#item_gallery').carouFredSel({
			synchronise: ['#item_gallery-thumbs', false, true],
			auto: false,
			items: 1,
			scroll: {
				onBefore: function( data ) {
					$('#item_gallery-thumbs li[index="' + data.items.old.eq(0).attr('index') + '"]').removeClass('selected');
					$('#item_gallery-thumbs li[index="' + data.items.visible.eq(0).attr('index') + '"]').addClass('selected');
				},
			},
			prev: '#item_gallery-prev',
			next: '#item_gallery-next',
			swipe: {
				onTouch: true
			}
		});
	}
};

$(function() {
    setTimeout(function () {
		createSliders();
	}, 1000); 
});
